import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import { authenticationGuard } from "@/auth/authenticationGuard";

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => {
    if (
      err.name !== "NavigationDuplicated" &&
      !err.message.includes("Avoided redundant navigation to current location")
    ) {
      // But print any other errors to the console
      // console.warn(err);
    }
  });
};

Vue.use(VueRouter);

const routes = [
  {
    // Document title tag
    // We combine it with defaultDocumentTitle set in `src/main.js` on router.afterEach hook
    meta: {
      title: "Pagina de pornire",
    },
    path: "/",
    name: "home",
    component: Home,
  },
  {
    meta: {
      title: "Registru Evidenta Vanzari",
    },
    path: "/Sales",
    name: "Sales",
    beforeEnter: authenticationGuard,
    component: () => import("../views/Sales.vue"),
  },
  {
    meta: {
      title: "Registru Urbanism",
    },
    path: "/Urbanism",
    name: "Urbanism",
    beforeEnter: authenticationGuard,
    component: () => import("../views/Urbanism.vue"),
  },
  {
    meta: {
      title: "Adaugare document",
    },
    path: "/urbanismdocuments/add",
    name: "UrbanismDocument",
    beforeEnter: authenticationGuard,
    component: () => import("../views/UrbanismDocument.vue"),
  },
  {
    meta: {
      title: "Editare document",
    },
    path: "/urbanismdocuments/:id",
    name: "urbanismdocument.edit",
    beforeEnter: authenticationGuard,
    component: () => import("../views/UrbanismDocument.vue"),
  },
  {
    meta: {
      title: "Registru Voluntariat",
    },
    path: "/Volunteering",
    name: "Volunteering",
    beforeEnter: authenticationGuard,
    component: () => import("../views/Volunteering.vue"),
  },
  {
    meta: {
      title: "Mesaje",
    },
    path: "/Messages",
    name: "Messages",
    beforeEnter: authenticationGuard,
    component: () => import("../views/Messages.vue"),
  },
  {
    meta: {
      title: "Adaugare voluntar",
    },
    path: "/volunteers/add",
    name: "Volunteer",
    beforeEnter: authenticationGuard,
    component: () => import("../views/Volunteer.vue"),
  },
  {
    meta: {
      title: "Editare voluntar",
    },
    path: "/volunteers/:id",
    name: "volunteer.edit",
    beforeEnter: authenticationGuard,
    component: () => import("../views/Volunteer.vue"),
  },
  {
    meta: {
      title: "Editare activitate voluntariat",
    },
    path: "/volunteers/:volunteerId/activities/:id",
    name: "volunteeractivity.edit",
    beforeEnter: authenticationGuard,
    component: () => import("../views/VolunteerActivity.vue"),
  },
  {
    meta: {
      title: "Adaugare activitate voluntariat",
    },
    path: "/volunteers/:volunteerId/activities/add",
    name: "volunteeractivity.add",
    beforeEnter: authenticationGuard,
    component: () => import("../views/VolunteerActivity.vue"),
  },
  {
    meta: {
      title: "Activitate voluntariat",
    },
    path: "/volunteers/:id/activities",
    name: "volunteerActivities",
    beforeEnter: authenticationGuard,
    component: () => import("../views/VolunteerActivities.vue"),
  },
  {
    meta: {
      title: "Anunturi",
    },
    path: "/Announcements",
    name: "Announcements",
    beforeEnter: authenticationGuard,
    component: () => import("../views/Announcements.vue"),
  },
  {
    meta: {
      title: "Oferte de vanzare",
    },
    path: "/SaleOffersAnnouncements",
    name: "SaleOffersAnnouncements",
    beforeEnter: authenticationGuard,
    component: () => import("../views/SaleOffersAnnouncements.vue"),
  },
  {
    meta: {
      title: "Documente",
    },
    path: "/BusinessLetters",
    name: "BusinessLetters",
    beforeEnter: authenticationGuard,
    component: () => import("../views/BusinessLetters.vue"),
  },
  {
    meta: {
      title: "Documente",
    },
    path: "/RegistryEntries",
    name: "RegistryEntries",
    beforeEnter: authenticationGuard,
    component: () => import("../views/registry/RegistryEntries.vue"),
  },
  {
    meta: {
      title: "Adaugare document",
    },
    path: "/registryEntries/add/:registryId",
    name: "registryEntry.add",
    beforeEnter: authenticationGuard,
    component: () => import("../views/registry/RegistryEntry.vue"),
  },
  {
    meta: {
      title: "Editare document",
    },
    path: "/registryEntries/:registryId/:id",
    name: "registryEntry.edit",
    beforeEnter: authenticationGuard,
    component: () => import("../views/registry/RegistryEntry.vue"),
  },
  {
    meta: {
      title: "Adaugare document",
    },
    path: "/businessLetters/add",
    name: "BusinessLetter",
    beforeEnter: authenticationGuard,
    component: () => import("../views/BusinessLetter.vue"),
  },
  {
    meta: {
      title: "Editare document",
    },
    path: "/businessLetter/:id",
    name: "businessLetter.edit",
    beforeEnter: authenticationGuard,
    component: () => import("../views/BusinessLetter.vue"),
  },
  // {
  //   meta: {
  //     title: "Editare oferta de vanzare",
  //   },
  //   path: "/saleOffer/:id",
  //   name: "saleOffer.edit",
  //   beforeEnter: authenticationGuard,
  //   component: () => import("../views/SaleOffer.vue"),
  // },
  // {
  //   meta: {
  //     title: "Oferte de vanzare",
  //   },
  //   path: "/SaleOffers",
  //   name: "SaleOffers",
  //   component: () => import("../views/SaleOffers.vue"),
  // },
  {
    meta: {
      title: "Editare inregistrare",
    },
    path: "/sale/:id",
    name: "sale.edit",
    beforeEnter: authenticationGuard,
    component: () => import("../views/Sale.vue"),
  },
  {
    meta: {
      title: "Editare anunt",
    },
    path: "/announcement/:id",
    name: "announcement.edit",
    beforeEnter: authenticationGuard,
    component: () => import("../views/Announcement.vue"),
  },
  {
    meta: {
      title: "Adaugare inregistrare",
    },
    path: "/sales/add",
    name: "Sale",
    beforeEnter: authenticationGuard,
    component: () => import("../views/Sale.vue"),
  },
  // {
  //   meta: {
  //     title: "Editare preepmtor",
  //   },
  //   path: "/saleOffer/:saleOfferId/preemption/:id",
  //   name: "preemption.edit",
  //   beforeEnter: authenticationGuard,
  //   component: () => import("../views/Preemption.vue"),
  // },
  // {
  //   meta: {
  //     title: "Vanzatori",
  //   },
  //   path: "/saleOffer/:id/sellers",
  //   name: "sellers",
  //   beforeEnter: authenticationGuard,
  //   component: () => import("../views/Sellers.vue"),
  // },
  // // {
  //   meta: {
  //     title: "Preemptori",
  //   },
  //   path: "/saleOffer/:id/preemptions",
  //   name: "preemptions",
  //   beforeEnter: authenticationGuard,
  //   component: () => import("../views/Preemptions.vue"),
  // },
  // {
  //   meta: {
  //     title: "Editare vanzator",
  //   },
  //   path: "/saleOffer/:saleOfferId/sellers/:id",
  //   name: "seller.edit",
  //   beforeEnter: authenticationGuard,
  //   component: () => import("../views/Seller.vue"),
  // },
  // {
  //   meta: {
  //     title: "Adaugare preemptor",
  //   },
  //   path: "/preemptions/add/:saleOfferId",
  //   name: "Preemption",
  //   beforeEnter: authenticationGuard,
  //   component: () => import("../views/Preemption.vue"),
  // },
  // {
  //   meta: {
  //     title: "Adaugare preemptor",
  //   },
  //   path: "/preemptions/add",
  //   name: "Preemption",
  //   beforeEnter: authenticationGuard,
  //   component: () => import("../views/Preemption.vue"),
  // },
  // {
  //   meta: {
  //     title: "Adaugare vanzator",
  //   },
  //   path: "/sellers/add/:saleOfferId",
  //   name: "Seller",
  //   beforeEnter: authenticationGuard,
  //   component: () => import("../views/Seller.vue"),
  // },
  // {
  //   meta: {
  //     title: "Adaugare vanzator",
  //   },
  //   path: "/sellers/add",
  //   name: "Seller",
  //   beforeEnter: authenticationGuard,
  //   component: () => import("../views/Seller.vue"),
  // },
  {
    meta: {
      title: "Adaugare anunt",
    },
    path: "/announcements/add",
    name: "Announcement",
    beforeEnter: authenticationGuard,
    component: () => import("../views/Announcement.vue"),
  },
  {
    meta: {
      title: "Adaugare oferta de vanzare",
    },
    path: "/saleOffersAnnouncements/add",
    name: "SaleOffersAnnouncement",
    beforeEnter: authenticationGuard,
    component: () => import("../views/SaleOffersAnnouncement.vue"),
  },
  // {
  //   meta: {
  //     title: "Adaugare oferta de vanzare",
  //   },
  //   path: "/saleOffers/add",
  //   name: "SaleOffer",
  //   component: () => import("../views/SaleOffer.vue"),
  // },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

export default router;
