import endpoints from "./endpoints";
import apiConfig from "../config/api";
import http from "./http";

const { getCityHallInfo } = endpoints;
const { baseURL } = apiConfig;

const CityHall = {
  async handleGetCityHallInfo(id) {
    const getCityHallInfoUrl = `${baseURL}${getCityHallInfo.GET(id)}`;
    return await http
      .get(
        getCityHallInfoUrl,
        {},
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .catch((error) => {
        return error;
      });
  },
};

export default CityHall;
