/* Styles */
import "@/scss/main.scss";

/* Core */
import Vue from "vue";
import Buefy from "buefy";

/* Router & Store */
import router from "./router";
import store from "./store";

// Import the Auth0 configuration
import { domain, clientId, audience } from "../auth_config.json";

// Import the plugin here
import { Auth0Plugin } from "./auth";
import NProgress from "vue-nprogress";

/* Service Worker */
import "./registerServiceWorker";

/* Vue. Main component */
import App from "./App.vue";

import { ValidationObserver, ValidationProvider, localize } from "vee-validate";
import veeValidateConfigure from "@/plugins/vee-validate";
import JsonExcel from "vue-json-excel";

import moment from "moment";

/* Default title tag */
const defaultDocumentTitle = "Primarie online";

/* Collapse mobile aside menu on route change & set title */
router.afterEach((to) => {
  store.commit("asideMobileStateToggle", false);
  store.commit("overlayToggle", false);
  store.commit("asideActiveForcedKeyToggle", null);

  if (to.meta && to.meta.title) {
    document.title = `${to.meta.title} — ${defaultDocumentTitle}`;
  } else {
    document.title = defaultDocumentTitle;
  }
});

Vue.config.productionTip = false;

Vue.use(Buefy);

// Progress bar library
Vue.use(NProgress);
const nprogress = new NProgress();

Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);
veeValidateConfigure();
Vue.component("downloadExcel", JsonExcel);

// Install the authentication plugin here
Vue.use(Auth0Plugin, {
  domain,
  clientId,
  audience,
  onRedirectCallback: (appState) => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  },
});

Vue.filter("formatDate", function (value) {
  if (value) {
    return moment(String(value)).format("DD/MM/YYYY");
  }
});

Vue.filter("formatDateTime", function (value) {
  if (value) {
    return moment(String(value)).format("DD/MM/YYYY HH:mm");
  }
});

new Vue({
  nprogress,
  router,
  store,
  render: (h) => h(App),
  mounted() {
    document.documentElement.classList.remove("has-spinner-active");
  },
}).$mount("#app");
