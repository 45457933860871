<template>
  <section class="hero is-hero-bar is-main-hero">
    <div class="hero-body">
      <div class="level">
        <div class="level-left">
          <div class="level-item is-hero-content-item">
            <div>
              <h1 class="title is-spaced">
                <b>Primaria {{ cityHall.name }}</b>
              </h1>
              <h3 class="subtitle" v-if="cityHall.website">
                Website: <b>{{ cityHall.website }}</b>
              </h3>
              <h3 class="subtitle" v-if="cityHall.cif">
                CIF: <b>{{ cityHall.cif }}</b>
              </h3>
              <h3 class="subtitle" v-if="cityHall.siruta">
                Cod SIRUTA: <b>{{ cityHall.siruta }}</b>
              </h3>
            </div>
          </div>
        </div>
        <div class="level-right">
          <div class="level-item">
            <router-link
              to="#"
              class="button is-light"
              title="Profile"
              disabled
            >
              <b-icon icon="domain" custom-size="default" />
              <span>Administreaza informatii</span>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: {
    cityHall: {
      type: Object,
      default: null,
    },
  },
  name: "HeroBarMain",
  data() {
    return {
      lastLoginTimeString: "12 mins ago",
      lastLoginIpString: "192.168.1.1",
    };
  },
  computed: {
    ...mapState(["userName", "userAvatar"]),
  },
};
</script>
