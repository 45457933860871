<template>
  <a class="navbar-item is-desktop-icon-only" title="Conectare" @click="login">
    Conectare
  </a>
</template>

<script>
export default {
  name: "LoginButton",
  data() {
    return {
      isLoading: false,
    };
  },
  methods: {
    login() {
      this.isLoading = true;
      if (!this.$auth.isAuthenticated) this.$auth.loginWithRedirect();
    },
  },
};
</script>
