<template>
  <section class="section is-title-bar">
    <div class="level">
      <div class="level-left">
        <div class="level-item">
          <ul>
            <li> {{titleStack[0]}}</li>
            <li v-if="volunteerId">{{titleStack[1]}}</li>
            <li v-if="titleStack[2]">{{ titleStack[2] }}</li>
            <li v-if="titleStack[3]">{{ titleStack[3] }}</li>
          </ul>
        </div>
        <div class="level-item">
          <ul>
            <!-- <li v-for="(title, index) in titleStack" :key="index">
              <router-link :to="'/saleOffer/' + saleOfferId">{{
                title
              }}</router-link>
            </li> -->
          </ul>
        </div>
      </div>
      <div class="level-right">
        <div class="level-item"></div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "TitleBar",
  props: {
    volunteerId: {
      type: Number,
      default: () => 0,
    },
    titleStack: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
