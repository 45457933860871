<template>
  <div>
    <div v-if="$auth.isAuthenticated">
      <title-bar :title-stack="titleStack" />
      <hero-bar-main :cityHall="cityHall" />
      <section class="section is-main-section">
        <div class="tile is-ancestor">
          <div class="tile is-parent" v-if="hasRegistraturaAccess() ||
            hasAgricolAccess() ||
            hasStareCivilaAccess()
            ">
            <article class="tile is-child box">
              <div class="card">
                <header class="card-header">
                  <p class="card-header-title">
                    <router-link :to="'RegistryEntries'">Registratura Electronica</router-link>
                  </p>
                </header>
                <div class="card-content">
                  <div class="content">
                    Registratura electronica permite inregistrarea documentelor
                    si pastrarea unei evidente a acestora.
                  </div>
                </div>
              </div>
            </article>
          </div>
          <div class="tile is-parent" v-if="hasRegistraturaAccess() || hasAgricolAccess()">
            <article class="tile is-child box">
              <div class="card">
                <header class="card-header">
                  <p class="card-header-title">
                    <router-link :to="'sales'">Registru Evidenta Vanzari</router-link>
                  </p>
                </header>
                <div class="card-content">
                  <div class="content">
                    Registrul electronic pentru evidenta ofertelor de vanzare a
                    terenurilor agricole situate in extravilan.
                  </div>
                </div>
              </div>
            </article>
          </div>
          <div class="tile is-parent" v-if="hasRegistraturaAccess()">
            <article class="tile is-child box">
              <div class="card">
                <header class="card-header">
                  <p class="card-header-title">
                    <router-link :to="'/volunteering/'">Registru Voluntariat</router-link>
                  </p>
                </header>
                <div class="card-content">
                  <div class="content">
                    Registrul de evidenta al voluntarilor, a duratei şi a
                    activitatilor desfasurate de acestia.
                  </div>
                </div>
              </div>
            </article>
          </div>
          <div class="tile is-parent" v-if="hasRegistraturaAccess()">
            <article class="tile is-child box">
              <div class="card">
                <header class="card-header">
                  <p class="card-header-title">Registru Invoiri</p>
                </header>
                <div class="card-content">
                  <div class="content">
                    Registrul de evidenta a invoirilor angajatilor
                  </div>
                </div>
              </div>
            </article>
          </div>
          <div class="tile is-parent" v-if="hasRegistraturaAccess()">
            <article class="tile is-child box">
              <div class="card">
                <header class="card-header">
                  <p class="card-header-title">
                    <router-link :to="'/urbanism/'">Urbanism</router-link>
                  </p>
                </header>
                <div class="card-content">
                  <div class="content">
                    Registrul de evidenta al documentelor din departamentul de
                    Urbanism
                  </div>
                </div>
              </div>
            </article>
          </div>
        </div>
      </section>
    </div>
    <div v-else>
      <section class="hero is-white is-fullheight">
        <div class="hero-body">
          <div class="container">
            <div class="columns is-vcentered reverse-columns">
              <div
                class="column is-10-mobile is-offset-1-mobile is-10-tablet is-offset-1-tablet is-5-desktop is-offset-1-desktop is-5-widescreen is-offset-1-widescreen is-5-fullhd is-offset-1-fullhd"
                data-aos="fade-down">
                <h1 class="title titled is-1 mb-6">
                  <vue-typer text="primarie.online"></vue-typer>
                </h1>
                <h2 class="subtitled subtitle has-text-grey is-4 has-text-weight-normal is-family-sans-serif">
                  <!-- Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                  Laborum cupiditate dolorum vitae dolores nesciunt totam magni
                  quas. -->
                </h2>
                <!-- <div class="buttons">
                  <button class="button is-black">Download</button>
                  <button class="button">Subscribe</button>
                </div> -->
              </div>
              <div data-aos="fade-right"
                class="column is-10-mobile is-offset-1-mobile is-10-tablet is-offset-1-tablet is-4-desktop is-offset-1-desktop is-4-widescreen is-offset-1-widescreen is-4-fullhd is-offset-1-fullhd">
                <figure class="image is-square">
                  <!-- <img src="../images/undraw_Camera_re_cnp4.svg" /> -->
                </figure>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="hero is-white is-fullheight">
        <div class="hero-body">
          <div class="container">
            <div class="columns is-vcentered">
              <div data-aos="fade-left"
                class="column is-10-mobile is-offset-1-mobile is-10-tablet is-offset-1-tablet is-4-desktop is-offset-1-desktop is-4-widescreen is-offset-1-widescreen is-4-fullhd is-offset-1-fullhd">
                <figure class="image is-square">
                  <!-- <img src="../images/undraw_Camera_re_cnp4.svg" /> -->
                </figure>
              </div>
              <div data-aos="fade-down"
                class="column is-10-mobile is-offset-1-mobile is-10-tablet is-offset-1-tablet is-5-desktop is-offset-1-desktop is-5-widescreen is-offset-1-widescreen is-5-fullhd is-offset-1-fullhd">
                <h1 class="titled title is-1 mb-6"></h1>
                <h2 class="subtitled subtitle">
                  <!-- Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                  Laborum cupiditate dolorum vitae dolores nesciunt totam magni
                  quas. -->
                </h2>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="hero is-white is-fullheight">
        <div class="hero-body">
          <div class="container">
            <div class="columns is-vcentered reverse-columns">
              <div data-aos="fade-right"
                class="column is-10-mobile is-offset-1-mobile is-10-tablet is-offset-1-tablet is-5-desktop is-offset-1-desktop is-5-widescreen is-offset-1-widescreen is-5-fullhd is-offset-1-fullhd">
                <h1 class="title titled is-1 mb-6"></h1>
                <h2 class="subtitle subtitled">
                  <!-- Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                  Laborum cupiditate dolorum vitae dolores nesciunt totam magni
                  quas. -->
                </h2>
              </div>
              <div data-aos="fade-down"
                class="column is-10-mobile is-offset-1-mobile is-10-tablet is-offset-1-tablet is-4-desktop is-offset-1-desktop is-4-widescreen is-offset-1-widescreen is-4-fullhd is-offset-1-fullhd">
                <figure class="image is-square">
                  <!-- <img src="../images/undraw_Camera_re_cnp4.svg" /> -->
                </figure>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import CityHall from "@/services/cityHall.service";
// @ is an alias to /src
import * as chartConfig from "@/components/Charts/chart.config";
import TitleBar from "@/components/TitleBar";
import HeroBarMain from "@/components/HeroBarMain";
import { VueTyper } from "vue-typer";
import { accessMixin } from "../mixins/accessMixin";

export default {
  name: "home",
  components: {
    HeroBarMain,
    TitleBar,
    VueTyper,
  },
  mixins: [accessMixin],
  data() {
    return {
      defaultChart: {
        extraOptions: chartConfig.chartOptionsMain,
      },
      cityHall: {},
    };
  },
  computed: {
    titleStack() {
      return ["Tablou de bord"];
    },
  },
  created() {
    this.getCityHallInfo(1).then((response) => {
      this.cityHall = response;
    });
  },
  methods: {
    async getCityHallInfo(id) {
      const { handleGetCityHallInfo } = CityHall;
      const response = await handleGetCityHallInfo(id);
      const { data } = response;
      return data;
    },
    actionSample() {
      this.$buefy.toast.open({
        message: "Some action",
        type: "is-info",
        queue: false,
      });
    },
  },
};
</script>
