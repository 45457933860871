<template>
  <nav v-show="isNavBarVisible" id="navbar-main" class="navbar is-fixed-top">
    <div class="navbar-brand">
      <a
        v-if="$auth.isAuthenticated"
        @click.prevent="menuToggle"
        :title="toggleTooltip"
        class="navbar-item is-desktop-icon-only is-hidden-touch"
      >
        <b-icon :icon="menuToggleIcon" />
      </a>
    </div>
    <div class="navbar-brand is-right">
      <a
        class="navbar-item navbar-item-menu-toggle is-hidden-desktop"
        @click.prevent="menuNavBarToggle"
      >
        <b-icon :icon="menuNavBarToggleIcon" custom-size="default" />
      </a>
    </div>
    <div
      class="navbar-menu fadeIn animated faster"
      :class="{ 'is-active': isMenuNavBarActive }"
    >
      <div class="navbar-end" v-if="$auth.isAuthenticated">
        <nav-bar-menu
          v-if="$auth.isAuthenticated"
          class="has-divider has-user-avatar"
        >
          <div class="is-user-name">
            <span>{{ $auth.user.name }}</span>
          </div>
        </nav-bar-menu>
        <logout-button />
      </div>
      <div class="navbar-end" v-else>
        <login-button />
      </div>
    </div>
  </nav>
</template>

<script>
import { mapState } from "vuex";
import NavBarMenu from "@/components/NavBarMenu";
import LogoutButton from "./LogoutButton.vue";
import LoginButton from "./LoginButton.vue";
export default {
  name: "NavBar",
  components: {
    NavBarMenu,
    LogoutButton,
    LoginButton,
  },
  data() {
    return {
      isMenuNavBarActive: false,
    };
  },
  computed: {
    menuNavBarToggleIcon() {
      return this.isMenuNavBarActive ? "close" : "dots-vertical";
    },
    menuToggleMobileIcon() {
      return this.isAsideMobileExpanded ? "backburger" : "forwardburger";
    },
    menuToggleIcon() {
      return this.isAsideExpanded ? "backburger" : "forwardburger";
    },
    toggleTooltip() {
      return this.isAsideExpanded ? "Collapse" : "Expand";
    },
    ...mapState([
      "isNavBarVisible",
      "isAsideExpanded",
      "isAsideMobileExpanded",
      "isAsideRightVisible",
      "userName",
      "hasUpdates",
    ]),
  },
  methods: {
    menuToggle() {
      this.$store.commit("asideStateToggle");
    },
    menuToggleMobile() {
      this.$store.commit("asideMobileStateToggle");
    },
    menuNavBarToggle() {
      this.isMenuNavBarActive = !this.isMenuNavBarActive;
    },
    updatesToggle() {
      this.$store.commit("asideRightToggle");
    },
    logout() {
      this.$buefy.snackbar.open({
        message: "Log out clicked",
        queue: false,
      });
    },
  },
};
</script>
