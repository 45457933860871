export const chartColors = {
  default: {
    primary: "#00D1B2",
    info: "#209CEE",
    danger: "#FF3860",
  },
};

export const baseChartOptions = {
  maintainAspectRatio: false,
  legend: {
    display: true,
  },
  responsive: true,
};

export const chartOptionsMain = {
  ...baseChartOptions,
  tooltips: {
    backgroundColor: "#f5f5f5",
    titleFontColor: "#333",
    bodyFontColor: "#666",
    bodySpacing: 1,
    xPadding: 3,
    mode: "nearest",
    intersect: 0,
    position: "nearest",
  },
};
