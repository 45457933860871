export default Object.freeze({
  getCityHallInfo: {
    GET: (id) => {
      return `cityHalls/${id}`;
    },
  },

  // how we can optimize saleOffer endpoint
  // saleOfferEndpoint: {
  //   GET(id) {
  //     return id ? `saleOffers/${id}` : 'saleOffers'
  //   }
  // },
  getAllSales: {
    GET: () => {
      return `electronicRegisterSalesOffers`;
    },
  },
  addSale: {
    GET: "electronicRegisterSalesOffers",
  },
  getSale: {
    GET: (id) => {
      return `electronicRegisterSalesOffers/${id}`;
    },
  },
  editSale: {
    GET: (id) => {
      return `electronicRegisterSalesOffers/${id}`;
    },
  },
  deleteSale: {
    GET: (id) => {
      return `electronicRegisterSalesOffers/${id}`;
    },
  },
  getSaleOfferDocuments: {
    GET: () => {
      return `saleOffers`;
    },
  },
  getAllSalesOffers: {
    GET: () => {
      return `saleOffers`;
    },
  },
  getSalesOffer: {
    GET: (id) => {
      return `saleOffers/${id}`;
    },
  },
  getSeller: {
    GET: (id) => {
      return `sellers/${id}`;
    },
  },
  editSeller: {
    GET: (id) => {
      return `sellers/${id}`;
    },
  },
  addSaleOffer: {
    GET: "saleOffers",
  },
  addSeller: {
    GET: "sellers",
  },
  addLand: {
    GET: "lands",
  },
  deleteSeller: {
    GET: (id) => {
      return `sellers/${id}`;
    },
  },
  addPreemption: {
    GET: "preemptions",
  },
  getPreemption: {
    GET: (id) => {
      return `preemptions/${id}`;
    },
  },
  editPreemption: {
    GET: (id) => {
      return `preemptions/${id}`;
    },
  },
  deletePreemption: {
    GET: (id) => {
      return `preemptions/${id}`;
    },
  },
  editSaleOffer: {
    GET: (id) => {
      return `saleOffers/${id}`;
    },
  },
  deleteSaleOffer: {
    GET: (id) => {
      return `saleOffers/${id}`;
    },
  },
});
