<template>
  <a
    class="navbar-item is-desktop-icon-only"
    title="Deconectare"
    @click="logout"
    >Deconectare
  </a>
</template>

<script>
export default {
  name: "LogoutButton",
  methods: {
    logout() {
      localStorage.removeItem("filters");
      this.$auth.logout({
        returnTo: window.location.origin,
        clientID: "lsOx4vXAoX8YaJqFaGk5mYRBsPAKi4oM",
      });
    },
  },
};
</script>
