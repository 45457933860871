export const accessMixin = {
  methods: {
    hasRegistraturaAccess() {
      let currentUserPermssions = this.$store.state.permissions[0];
      return currentUserPermssions[0] === "registratura";
    },
    hasAgricolAccess() {
      let currentUserPermssions = this.$store.state.permissions[0];
      return currentUserPermssions[0] === "agricol";
    },
    hasStareCivilaAccess() {
      let currentUserPermssions = this.$store.state.permissions[0];
      return currentUserPermssions[0] === "stare civila";
    },

    hasAdminToRegistry() {
      let currentUserPermssions = this.$store.state.permissions[0];
      let isRegistraturaAdmin = currentUserPermssions[0] === "registratura";
      var retrievedObject = JSON.parse(localStorage.getItem("filters"));
      let isAgricolAdmin =
        currentUserPermssions[0] === "agricol" &&
        retrievedObject.registryId === 1;

      return isRegistraturaAdmin || isAgricolAdmin;
    },
  },
};
